<template>
  <div>
    <CDropdownItem @click="action">Delete</CDropdownItem>
  </div>
</template>


<script>
import { CModal } from "@coreui/vue/src/components/modal";
import Vue from "vue";
var ComponentClass = Vue.extend(CModal);

export default {
  name: "ConfirmationModel",
  props: {
    title: "Confirm",
    parent: null,
    description: "",
  },
  data() {
    return {
      showConfirmation: false,
      instance: null,
    };
  },
  mounted() {
    this.instance = new ComponentClass();
    this.instance.$props.title = "Confirm";
    this.instance.$slots.default = [this.description];
    this.instance.$mount();
    this.instance.$on("update:show", this.actionOk);
    this.parent.appendChild(this.instance.$el);
  },
  methods: {
    action() {
      console.log("delete action called");
      this.instance.show = true;
    },
    actionOk(flag, e, accept) {
      console.log(flag, e, accept);
      this.instance.show = false;
      if (accept) {
        console.log("condition is accepted");
        this.$emit("on:ok", e, accept);
      } else {
        this.$emit("on:no", e, accept);
      }
    },
  },
};
</script>
