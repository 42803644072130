<template>
  <div>
  <CCard>
   
  <CCardBody>
    <!-- <div class="clearfix">
      <button type="button" class="btn btn-secondary float-xl-right" @click="navToNewSymptoms">New </button>
    </div> -->

    <CDataTable :items="items"  
    :fields="fields"
      striped
      clickable-rows
      column-filter
       table-filter
        items-per-page-select
      :items-per-page="20"
       sorter
       ref="vuetable"
      pagination>
       
      <template #index="data">
       <td>
         {{data.index +1}}
         </td>
     </template>

     
        <template #submittedUserName="{item}">
        <td class='font-weight-bold'
          v-if="item.firstName && item.lastName"
        >{{ item.firstName }} {{item.lastName }}</td>
        <td v-else>--</td>
     </template>
      <template #role="{ item }">
          <td class='font-weight-bold' v-if="item.role">
            {{ item.role }}
          </td>
          <td v-else>--</td>
        </template>
        <template #createdTime="{item}">
        <td class='font-weight-bold'
          v-if="item.createdTime"
        >{{ item.createdTime |dateParse()| dateFormat("MMM D, YYYY hh:mm A") }}</td>
        <td v-else>--</td>
      </template>
   
      </CDataTable>
  </CCardBody>
  <div ref="modalArea"></div>
 <CCardFooter align="right">
         
            <CButton class="mr-2" type="Cancel" size="sm" color="danger"  @click="cancel()">
              <CIcon name="cil-ban" /> Cancel
            </CButton>
          </CCardFooter>
 </CCard>
 </div>
</template>

<script>
const fields = [
  'index',
  
  { key: "submittedUserName",label: "User Name", _classes: 'font-weight-bold',  _style: "min-width:200px" },
  { key: "role",label: "Role", _classes: 'font-weight-bold',  _style: "min-width:200px" },
  { key: "createdTime",label: "Assigned Date & Time", _classes: 'font-weight-bold',  _style: "min-width:200px" },

 

  
];
import ConfirmationModal from "../../containers/ConfirmationModal";
export default {
  name: "JobAssignedUsers",
  components: {
    ConfirmationModal,
  },
  data() {
    return {
      items: [],
      fields,
      details: [],
      collapseDuration: 0,
    };
  },
  methods: {
    getJobAssignedUsers() {
      this.apiGetSecure(process.env.VUE_APP_API_HOST + "/emapp/web/secure/get/job/assigned/users/"+this.$route.params.jobId)
        .then((response) => response.json())
        .then((data) => (this.items = data));
    },
  
     cancel() {
      window.history.back(); 
    },
  },
  mounted() {
    this.getJobAssignedUsers();
  },
};
</script>